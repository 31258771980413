import { cn } from '@/Lib'
import * as SelectPrimitive from '@radix-ui/react-select'
import { AnimatePresence, MotionProps, motion } from 'framer-motion'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import { Icon } from '.'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

/**
 * Should only be used in the prepend/append slot of a TextField component.
 */
const SelectSlottedTrigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      '-m-3 flex h-full min-w-full items-center bg-nav px-4 text-medium ring-offset-0 placeholder:text-disabled focus:bg-nav-active focus:outline-none focus-visible:bg-nav-active focus-visible:ring-0 disabled:cursor-not-allowed [&>span]:line-clamp-1',
      className
    )}
    {...props}
  >
    {children}
  </SelectPrimitive.Trigger>
))
SelectSlottedTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectTrigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      'flex h-12 min-w-full items-center justify-between overflow-hidden rounded-xl border-2 border-base bg-transparent pl-4 text-left text-sm ring-offset-0 placeholder:text-disabled focus:border-focus focus:outline-none focus-visible:border-focus focus-visible:ring-0 disabled:cursor-not-allowed [&>span]:line-clamp-1',
      className
    )}
    {...props}
  >
    {children}
    <div className='ml-3 flex h-full items-center bg-nav px-4 text-medium'>
      <SelectPrimitive.Icon asChild>
        <Icon>unfold_more</Icon>
      </SelectPrimitive.Icon>
    </div>
  </SelectPrimitive.Trigger>
))
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectScrollUpButton = forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn(
      'flex cursor-default items-center justify-center py-1',
      className
    )}
    {...props}
  >
    <Icon>expand_less</Icon>
  </SelectPrimitive.ScrollUpButton>
))
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName

const SelectScrollDownButton = forwardRef<
  ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      'flex cursor-default items-center justify-center py-1',
      className
    )}
    {...props}
  >
    <Icon>expand_more</Icon>
  </SelectPrimitive.ScrollDownButton>
))
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName

const SelectContent = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'relative z-50 max-h-96 overflow-auto rounded-xl bg-nav text-sm shadow-lg focus:outline-none', // data-[state=closed]:animate-contentHide data-[state=open]:animate-contentShow
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-[var(--radix-select-trigger-width)] min-w-max'
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = forwardRef<
  ElementRef<typeof SelectPrimitive.Label>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('mb-1 ml-2 block text-sm font-medium text-medium', className)}
    {...props}
  />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex h-12 w-full min-w-max cursor-default select-none items-center justify-between gap-2 rounded-lg pl-4 pr-3 text-sm outline-none focus:bg-nav-active data-[disabled]:cursor-not-allowed data-[disabled]:text-disabled',
      className
    )}
    {...props}
  >
    {children}
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectItemText = forwardRef<
  ElementRef<typeof SelectPrimitive.ItemText>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ItemText>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.ItemText ref={ref} className={className} {...props}>
    {children}
  </SelectPrimitive.ItemText>
))
SelectItemText.displayName = SelectPrimitive.ItemText.displayName

const SelectItemIndicator = forwardRef<
  ElementRef<typeof SelectPrimitive.ItemIndicator>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.ItemIndicator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ItemIndicator
    ref={ref}
    className={cn('flex items-center', className)}
    {...props}
  >
    <Icon className='text-primary'>check</Icon>
  </SelectPrimitive.ItemIndicator>
))
SelectItemIndicator.displayName = SelectPrimitive.ItemIndicator.displayName

const SelectSeparator = forwardRef<
  ElementRef<typeof SelectPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-slate-100', className)}
    {...props}
  />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

interface SelectFormHintProps extends MotionProps {
  hint?: string
  error?: string
  className?: string
}

function SelectHint({ hint, error, className, ...props }: SelectFormHintProps) {
  return (
    <AnimatePresence>
      {error && (
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: -8 }}
          exit={{
            opacity: 0,
            y: -8,
            transition: {
              duration: 0.2
            }
          }}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 20,
            duration: 0.3
          }}
          className={cn('h-7 pl-2 pt-2', className)}
          {...props}
        >
          <p className='text-sm text-error'>{error}</p>
        </motion.div>
      )}
      {!error && hint && (
        <motion.div
          animate={{ opacity: 1, y: 0 }}
          initial={{ opacity: 0, y: -8 }}
          exit={{
            opacity: 0,
            y: -8,
            transition: {
              duration: 0.2
            }
          }}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 20,
            duration: 0.3
          }}
          className={cn('h-7 pl-2 pt-2', className)}
          {...props}
        >
          <p className='text-sm text-medium'>{hint}</p>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export {
  Select,
  SelectContent,
  SelectGroup,
  SelectHint,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectSeparator,
  SelectSlottedTrigger,
  SelectTrigger,
  SelectValue
}
