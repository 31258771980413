import { cn } from '@/Lib'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

const GoogleTag = forwardRef<
  HTMLIFrameElement,
  HTMLAttributes<HTMLIFrameElement>
>(({ className, ...props }, ref) => {
  return (
    <iframe
      ref={ref}
      src='https://gt.fynbos.money/'
      width='0'
      height='0'
      style={{ display: 'none' }}
      sandbox='allow-scripts allow-same-origin'
      className={cn(className)}
      title='Google Tag Sandbox'
      {...props}
    />
  )
})

GoogleTag.displayName = 'GoogleTag'

export { GoogleTag }
