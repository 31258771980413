import { Colours } from '@/Components/Colours'
import { cn } from '@/Lib'
import { forwardRef, HTMLAttributes, useMemo } from 'react'

export const Pill = forwardRef<
  HTMLSpanElement,
  HTMLAttributes<HTMLSpanElement> & { colour?: Colours }
>(({ className, colour, children, ...props }, ref) => {
  const colourVariants = useMemo(() => {
    switch (colour) {
      case Colours.red:
        return 'text-red-500 bg-red-50'
      case Colours.sky:
        return 'text-sky-500 bg-sky-50'
      case Colours.green:
        return 'text-green-500 bg-green-50'
      case Colours.orange:
        return 'text-orange-500 bg-orange-50'
      case Colours.blue:
        return 'text-blue-500 bg-blue-50'
      case Colours.indigo:
        return 'text-indigo-500 bg-indigo-50'
      case Colours.purple:
        return 'text-purple-500 bg-purple-50'
      case Colours.yellow:
        return 'text-yellow-500 bg-yellow-50'
      case Colours.lime:
        return 'text-lime-500 bg-lime-50'
      case Colours.rose:
        return 'text-rose-500 bg-rose-50'
      case Colours.slate:
        return 'text-medium bg-slate-50'
      default:
        return 'text-disabled bg-slate-100'
    }
  }, [colour])

  return (
    <span
      ref={ref}
      className={cn(
        'inline-block rounded-full px-2 py-1 align-baseline font-medium leading-none',
        className,
        colourVariants
      )}
      {...props}
    >
      <span className='inline-flex gap-x-1 align-top'>{children}</span>
    </span>
  )
})

Pill.displayName = 'Pill'
