/**
 * This file is auto generated, see readme for details
 */

export const Icons: Record<string, string> = {
  schedule: '\uefd6',
  navigate_next: '\ue5cc',
  arrow_split: '\uea04',
  add: '\ue145',
  check_small: '\uf88b',
  check: '\ue5ca',
  arrow_right_alt: '\ue941',
  vertical_align_top: '\ue25a',
  diamond: '\uead5',
  open_in_new: '\ue89e',
  cake: '\ue7e9',
  rocket_launch: '\ueb9b',
  block: '\uf08c',
  delete: '\ue92e',
  repeat: '\ue040',
  account_balance: '\ue84f',
  east: '\uf1df',
  west: '\uf1e6',
  sync_alt: '\uea18',
  local_mall: '\ue54c',
  sell: '\uf05b',
  card_membership: '\ue8f7',
  trending_up: '\ue8e5',
  undo: '\ue166',
  menu: '\ue5d2',
  menu_open: '\ue9bd',
  expand_more: '\ue5cf',
  local_library: '\ue54b',
  keyboard_arrow_left: '\ue314',
  keyboard_arrow_right: '\ue315',
  arrow_back: '\ue5c4',
  savings: '\ue2eb',
  home: '\ue9b2',
  receipt: '\ue8b0',
  automation: '\uf421',
  settings: '\ue8b8',
  support: '\uef73',
  logout: '\ue9ba',
  close: '\ue5cd',
  unfold_more: '\ue5d7',
  expand_less: '\ue5ce',
  radio_button_unchecked: '\ue836',
  radio_button_checked: '\ue837',
  share: '\ue80d',
  content_copy: '\ue14d'
}
