export enum Colours {
  red = 0,
  sky = 1,
  green = 2,
  orange = 3,
  blue = 4,
  indigo = 5,
  purple = 6,
  yellow = 7,
  lime = 8,
  rose = 9,
  slate = 10
}
