import { cn } from '@/Lib'
import { FC, ReactNode, useMemo } from 'react'
import { Icons } from './Icons'

type IconProps = {
  children?: ReactNode
  className?: string
  large?: boolean
}

/**
 * Icon will display a material icon.
 * Icon name will be replaced by its codepoint when one exists.
 *
 * @param className
 * @param children The material icon name
 * @param large Whether to use a larger icon
 */
export const Icon: FC<IconProps> = ({ className, children, large = false }) => {
  const codepointOrChildren = useMemo(() => {
    if (
      typeof children == 'string' &&
      Object.keys(Icons).indexOf(children) > -1
    ) {
      return Icons[children]
    }

    return children
  }, [children])

  return (
    <span
      aria-hidden='true'
      className={cn(
        'inline-block select-none whitespace-nowrap fill-current text-center font-normal normal-case not-italic tracking-normal antialiased',
        large
          ? 'size-12 font-icon-large text-5xl leading-[48px]'
          : 'size-6 font-icon text-2xl leading-6',
        className
      )}
    >
      {codepointOrChildren}
    </span>
  )
}
