import { Chip } from '@/Components/Chip'
import { Colours } from '@/Components/Colours'
import { cn } from '@/Lib'
import { forwardRef, HTMLAttributes, ReactNode, useMemo } from 'react'

type Tags = {
  colour: Colours
  text: string
}

type Asset = {
  name: string
  code: string
  description: ReactNode
  tags?: Tags[]
}

const assets: Asset[] = [
  {
    name: '1nvest S&P500 Info Tech',
    code: 'ETF5IT',
    description:
      'Companies from the S&P 500 within the GICS information technology sector.',
    tags: [
      { colour: Colours.blue, text: 'USA' },
      { colour: Colours.yellow, text: 'Large Companies' },
      { colour: Colours.purple, text: 'Tech Sector' },
      { colour: Colours.indigo, text: 'Industrialised Economies' },
      { colour: Colours.orange, text: 'Feeder Fund' }
    ]
  },
  {
    name: 'Sygnia Itrix S&P 500',
    code: 'SYG500',
    description: 'The 500 largest companies in the US.',
    tags: [
      { colour: Colours.blue, text: 'USA' },
      { colour: Colours.yellow, text: 'Large Companies' },
      { colour: Colours.purple, text: 'All Industries' },
      { colour: Colours.indigo, text: 'Industrialised Economies' },
      { colour: Colours.orange, text: 'Direct' }
    ]
  },
  {
    name: '10X Total World',
    code: 'GLOBAL',
    description: '9000+ global companies of all sizes.',
    tags: [
      { colour: Colours.blue, text: 'Global' },
      { colour: Colours.yellow, text: 'Small-Large Companies' },
      { colour: Colours.purple, text: 'All Industries' },
      { colour: Colours.indigo, text: 'Industrialised and Emerging Economies' },
      { colour: Colours.orange, text: 'Feeder Fund' }
    ]
  },
  {
    name: 'FNB Global 1200 Equity FoF',
    code: 'FNBEQF',
    description:
      'Top 1200 global companies. 70% of global market capitalization.',
    tags: [
      { colour: Colours.blue, text: 'Global' },
      { colour: Colours.yellow, text: 'Large Companies' },
      { colour: Colours.purple, text: 'All Industries' },
      { colour: Colours.indigo, text: 'Industrialised Economies' },
      { colour: Colours.orange, text: 'Fund of Funds' }
    ]
  },
  {
    name: 'Satrix MSCI World',
    code: 'STXWDM',
    description: 'Large and medium size businesses in 23 developed markets.',
    tags: [
      { colour: Colours.blue, text: 'Global' },
      { colour: Colours.yellow, text: 'Medium-Large Companies' },
      { colour: Colours.purple, text: 'All Industries' },
      { colour: Colours.indigo, text: 'Industrialised Economies' },
      { colour: Colours.orange, text: 'Feeder Fund' }
    ]
  },
  {
    name: 'Allan Gray money market',
    code: 'AGMF',
    description: (
      <>
        Offers a combination of safety, liquidity, and good returns.
        <br />
        {/* TODO Pull currentEffectiveYield from the backend */}
        Currently earning 8.47% per year.
      </>
    )
  }
]

export const AssetCard = forwardRef<
  HTMLDivElement,
  { assetCode: string; chipColor?: Colours } & HTMLAttributes<HTMLDivElement>
>(({ assetCode, chipColor = Colours.slate, className, ...props }, ref) => {
  const asset = useMemo(() => {
    // TODO Check if we need to handle lowercase
    return assets.find((val) => val.code == assetCode)
  }, [assetCode])

  if (!asset) return null

  return (
    <div
      ref={ref}
      className={cn(
        'my-2 flex w-full flex-col gap-2 rounded-xl bg-nav p-4 last:mb-0',
        className
      )}
      {...props}
    >
      <div className='flex w-full items-center justify-between'>
        <span className='font-medium text-strong'>{asset.name}</span>
        <Chip className='capitalize' colour={chipColor}>
          {asset.code}
        </Chip>
      </div>

      <span className='text-sm'>{asset.description}</span>
      {asset.tags && (
        <div className='flex flex-wrap gap-2'>
          {asset.tags.map((tag) => {
            return (
              <Chip
                key={'tag' + asset.code + tag.colour}
                colour={tag.colour}
                muted
              >
                {tag.text}
              </Chip>
            )
          })}
        </div>
      )}
    </div>
  )
})
AssetCard.displayName = 'AssetCard'
